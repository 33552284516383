import { useCallback, useEffect, useState } from "react";
import { useCurrentFrame, useVideoConfig } from "remotion";
import { type LofiVideoProps, LofiVideoSchema } from "../LofiVideo/example";

export const useLofiVideo = (props: LofiVideoProps) => {
	const frame = useCurrentFrame();
	const { fps } = useVideoConfig();
	const [currentTrack, setCurrentTrack] = useState(0);

	// Validate props using Zod
	const validateProps = useCallback(() => {
		try {
			LofiVideoSchema.parse(props);
			return true;
		} catch (error) {
			console.error("Invalid LofiVideo props:", error);
			return false;
		}
	}, [props]);

	// Calculate current music track based on frame, fps, and maxLoop
	useEffect(() => {
		const totalSeconds = frame / fps;
		let accumulatedSeconds = 0;
		const totalDuration = props.musics.reduce(
			(acc, music) => acc + music.seconds,
			0,
		);

		const loopedFrame = totalSeconds % totalDuration;

		for (let i = 0; i < props.musics.length; i++) {
			accumulatedSeconds += props.musics[i].seconds;
			if (loopedFrame < accumulatedSeconds) {
				setCurrentTrack(i);
				break;
			}
		}
	}, [frame, fps, props.musics]);

	// Calculate total duration in frames
	const getTotalFrames = useCallback(() => {
		const totalSeconds = props.musics.reduce(
			(acc, music) => acc + music.seconds,
			0,
		);
		return totalSeconds * fps;
	}, [props.musics, fps]);

	return {
		currentTrack,
		isValid: validateProps(),
		totalFrames: getTotalFrames(),
		currentFrame: frame,
		fps,
	};
};
