import {
	AbsoluteFill,
	Sequence,
	useCurrentFrame,
	useVideoConfig,
} from "remotion";
import { Word } from "./Word";
import type { z } from "zod";
import type { PrayerVideoSchema } from ".";

type WordChunk = z.infer<
	typeof PrayerVideoSchema
>["voice_over_transcription"]["chunks"][number];

export const Captions: React.FC<{ wordChunks: WordChunk[] }> = ({
	wordChunks,
}) => {
	const frame = useCurrentFrame();
	const { fps } = useVideoConfig();

	return wordChunks.map((chunk, index) => (
		<Sequence
			key={String(chunk.timestamp[0])}
			durationInFrames={chunk.timestamp[1] * fps - chunk.timestamp[0] * fps}
			from={chunk.timestamp[0] * fps}
		>
			{index === 0 ? (
				<AbsoluteFill>
					<div
						style={{
							backgroundColor: "rgba(255, 255, 255, 0.5)",
							borderRadius: "24px",
							textAlign: "center",
							position: "absolute",
							padding: "24px",
							width: "980px",
							top: "35%",
							textTransform: "capitalize",
							left: "8%",
							right: "10%",
						}}
					>
						{chunk.text.split(" ").map((word) => (
							<Word
								key={String(word)}
								word={{ text: word }}
								frame={frame}
								fontSize={64}
							/>
						))}
					</div>
				</AbsoluteFill>
			) : (
				<AbsoluteFill>
					<div
						style={{
							backgroundColor: "rgba(255, 255, 255, 0.5)",
							borderRadius: "24px",
							textAlign: "center",
							position: "absolute",
							padding: "24px",
							width: "980px",
							top: "30%",
							left: "8%",
							right: "10%",
						}}
					>
						{chunk.text.split(" ").map((word) => (
							<Word key={String(word)} word={{ text: word }} frame={frame} />
						))}
					</div>
				</AbsoluteFill>
			)}
		</Sequence>
	));
};
