import {
	random,
	useCurrentFrame,
	interpolate,
	spring,
	AbsoluteFill,
} from "remotion";

interface SparkleProps {
	startX: number;
	startY: number;
	delay: number;
}

export const Sparkle: React.FC<SparkleProps> = ({ startX, startY, delay }) => {
	const frame = useCurrentFrame();
	const movement = spring({
		frame: frame - delay,
		fps: 30,
		config: {
			damping: 20,
			stiffness: 200,
		},
	});

	const scale = 1 + 0.8 * Math.sin((frame - delay) / 10);

	const opacity = interpolate(scale, [0.2, 1], [0, 1]);

	return (
		<div
			style={{
				position: "absolute",
				left: `${startX}%`,
				top: `${startY}%`,
				opacity: opacity,
				transform: `scale(${scale}) translateY(${movement * 5}px)`,
				width: 5,
				height: 5,
				backgroundColor: "#FEEC37",
				borderRadius: "50%",
				boxShadow: "0px 0px 10px 10px rgba(254, 236, 55, 1)",
			}}
		/>
	);
};

export const Sparkles: React.FC = () => {
	const frame = useCurrentFrame();

	const sparkles = new Array(4).fill(true).map((_, i) => ({
		id: i,
		startX: random(`x-${i}`) * 100,
		startY: random(`y-${i}`) * 100,
		delay: random(`delay-${i}`) * 60,
	}));

	const updatedSparkles = sparkles.map((sparkle) => {
		const scale = 1 + 0.8 * Math.sin((frame - sparkle.delay) / 10);
		if (scale < 0.2) {
			return {
				...sparkle,
				startX: random(`new-x-${sparkle.id}`) * 100,
				startY: random(`new-y-${sparkle.id}`) * 100,
			};
		}
		return sparkle;
	});

	return (
		<AbsoluteFill>
			{updatedSparkles.map((sparkle) => (
				<Sparkle
					key={sparkle.id}
					startX={sparkle.startX}
					startY={sparkle.startY}
					delay={sparkle.delay}
				/>
			))}
		</AbsoluteFill>
	);
};

export default Sparkles;
