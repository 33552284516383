import { loadFont } from "@remotion/google-fonts/Poppins";
import { interpolateColors } from "remotion";
const { fontFamily } = loadFont();

const wordStyle = {
	fontSize: 75,
	fontFamily,
	fontWeight: "bold",
	textShadow: "0 0 10px #000",
	zIndex: 1,
	WebkitTextStrokeWidth: 2,
	WebkitTextStrokeColor: "black",
	position: "relative",
};

const wrapperStyle = {
	display: "inline-block",
	paddingLeft: 10,
	paddingRight: 10,
	position: "relative",
};

interface WordProps {
	word: {
		text: string;
		start: number;
	};
	frame: number;
}

export const Word: React.FC<WordProps> = ({ word, frame }) => {
	const color = interpolateColors(
		frame,
		[word.start - 4, word.start],
		["white", "#ffafae"],
	);
	const style = {
		...wordStyle,
		color,
	};
	return (
		<div style={wrapperStyle}>
			<span style={style}>{word.text}</span>
		</div>
	);
};
