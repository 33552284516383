import { staticFile } from "remotion";
import { z } from "zod";

const colorSchema = z.object({
	primary: z.string().min(7).max(7),
	secondary: z.string().min(7).max(7),
	accent: z.string().min(7).max(7),
	background: z.string().min(7).max(7),
	light: z.string().min(7).max(7),
	dark: z.string().min(7).max(7),
});

export const LofiVideoSchema = z.object({
	title: z.string(),
	music_background_url: z.string(),
	musics: z.array(
		z.object({
			url: z.string(),
			title: z.string(),
			seconds: z.number(),
		}),
	),
	theme: z.number().min(0).max(8),
});

export type LofiVideoProps = z.infer<typeof LofiVideoSchema>;
export type ColorsProps = z.infer<typeof colorSchema>;

const example: LofiVideoProps = {
	title: "Relaxing Lofi Compilation",
	music_background_url: staticFile("lofi/rain.mp3"),
	musics: [
		{
			url: staticFile("playlist/music-2.mp3"),
			title: "Moonlit Melody",
			seconds: 123.6,
		},
		{
			url: staticFile("playlist/music-1.mp3"),
			title: "Serene Sunrise",
			seconds: 240,
		},
	],
	theme: 1,
};
export default example;
