import "./tailwind.css";
import { Composition } from "remotion";
import { Logo, myCompSchema2 } from "./HelloWorld/Logo";
import { ShortVideo, ShortVideoSchema } from "./ShortVideo";
import example from "./ShortVideo/example";
import examplePrayer from "./PrayerVideo/example";
import exampleLofi, { LofiVideoSchema } from "./LofiVideo/example";
import { HelloWorld, myCompSchema } from "./HelloWorld";
import { PrayerVideo, PrayerVideoSchema } from "./PrayerVideo";
import { LofiVideo } from "./LofiVideo";
import { PlaylistVideo, PlaylistVideoSchema } from "./PlaylistVideo";
import examplePlaylist from "./PlaylistVideo/example";
import { PlaylistVideoThumbnail } from "./PlaylistVideo/thumbnail";
import { themes } from "./utils/colors";

export const RemotionRoot: React.FC = () => {
	return (
		<>
			<Composition
				id="PrayerVideo"
				component={PrayerVideo}
				width={1920}
				height={1080}
				fps={30}
				schema={PrayerVideoSchema}
				defaultProps={examplePrayer}
				calculateMetadata={async ({ props }) => {
					return {
						durationInFrames: Math.floor(props.duration_in_seconds * 30),
					};
				}}
			/>

			<Composition
				id="LofiVideo"
				component={LofiVideo}
				width={1920}
				height={1080}
				fps={30}
				schema={LofiVideoSchema}
				defaultProps={exampleLofi}
				calculateMetadata={async ({ props }) => {
					const singleLoopDuration = props.musics.reduce(
						(total, music) => total + music.seconds,
						0,
					);
					return {
						durationInFrames: Math.floor(singleLoopDuration * 30),
					};
				}}
			/>

			<Composition
				id="PlaylistVideo"
				component={PlaylistVideo}
				width={1920}
				height={1080}
				fps={30}
				schema={PlaylistVideoSchema}
				defaultProps={examplePlaylist}
				calculateMetadata={async ({ props }) => {
					const singleLoopDuration = props.musics.reduce(
						(total, music) => total + music.seconds,
						0,
					);
					return {
						durationInFrames: Math.floor(
							singleLoopDuration * props.maxLoop * 30,
						),
					};
				}}
			/>

			<Composition
				id="PlaylistVideoThumbnail"
				component={PlaylistVideoThumbnail}
				width={1920}
				height={1080}
				fps={30}
				schema={PlaylistVideoSchema}
				defaultProps={examplePlaylist}
				durationInFrames={1}
			/>

			<Composition
				id="ShortVideo"
				component={ShortVideo}
				width={1080}
				height={1920}
				fps={30}
				schema={ShortVideoSchema}
				defaultProps={example}
				calculateMetadata={async ({ props }) => {
					return {
						durationInFrames: Math.floor(props.duration_in_seconds * 30),
					};
				}}
			/>

			<Composition
				id="HelloWorld"
				component={HelloWorld}
				durationInFrames={150}
				fps={30}
				width={1920}
				height={1080}
				schema={myCompSchema}
				defaultProps={{
					titleText: "Welcome to Remotion",
					titleColor: "#000000",
					logoColor1: "#91EAE4",
					logoColor2: "#86A8E7",
				}}
			/>

			<Composition
				id="OnlyLogo"
				component={Logo}
				durationInFrames={150}
				fps={30}
				width={1920}
				height={1080}
				schema={myCompSchema2}
				defaultProps={{
					logoColor1: "#91dAE2" as const,
					logoColor2: "#86A8E7" as const,
				}}
			/>
		</>
	);
};
