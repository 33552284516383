import "./tailwind.css";
import { Composition } from "remotion";
import { Logo, myCompSchema2 } from "./HelloWorld/Logo";
import { ShortVideo } from "./ShortVideo";
import example from "./ShortVideo/example";
import examplePrayer from "./PrayerVideo/example";
import { HelloWorld, myCompSchema } from "./HelloWorld";
import { PrayerVideo } from "./PrayerVideo";

export const RemotionRoot: React.FC = () => {
	return (
		<>
			<Composition
				id="PrayerVideo"
				component={PrayerVideo}
				width={1920}
				height={1080}
				defaultProps={examplePrayer}
				fps={30}
				calculateMetadata={async ({ props }) => {
					return {
						durationInFrames: Math.floor(props.duration_in_seconds * 30),
					};
				}}
			/>

			<Composition
				id="ShortVideo"
				component={ShortVideo}
				width={1080}
				height={1920}
				defaultProps={example}
				fps={30}
				calculateMetadata={async ({ props }) => {
					return {
						durationInFrames: Math.floor(props.duration_in_seconds * 30),
					};
				}}
			/>

			<Composition
				id="HelloWorld"
				component={HelloWorld}
				durationInFrames={150}
				fps={30}
				width={1920}
				height={1080}
				schema={myCompSchema}
				defaultProps={{
					titleText: "Welcome to Remotion",
					titleColor: "#000000",
					logoColor1: "#91EAE4",
					logoColor2: "#86A8E7",
				}}
			/>

			<Composition
				id="OnlyLogo"
				component={Logo}
				durationInFrames={150}
				fps={30}
				width={1920}
				height={1080}
				schema={myCompSchema2}
				defaultProps={{
					logoColor1: "#91dAE2" as const,
					logoColor2: "#86A8E7" as const,
				}}
			/>
		</>
	);
};
