import { staticFile } from "remotion";
import type { PlaylistVideoSchema } from ".";
import type { z } from "zod";

const examplePlaylist: z.infer<typeof PlaylistVideoSchema> = {
  title: "Faith OverComes Fear",
  subtitle: "Trending Worship Songs For 2024",
  musics: [
    { url: staticFile("playlist/music-1.mp3"), title: "Serene Sunrise", seconds: 240 },
    { url: staticFile("playlist/music-2.mp3"), title: "Moonlit Melody", seconds: 200 },
    { url: staticFile("playlist/music-3.mp3"), title: "Starlight Symphony", seconds: 180 },
    { url: staticFile("playlist/music-3.mp3"), title: "Echoes of Eternity", seconds: 220 },
    { url: staticFile("playlist/music-3.mp3"), title: "Windswept Dreams", seconds: 190 },
    { url: staticFile("playlist/music-3.mp3"), title: "Harmony Horizons", seconds: 250 },
    { url: staticFile("playlist/music-3.mp3"), title: "Melodic Voyage", seconds: 230 },
    { url: staticFile("playlist/music-3.mp3"), title: "Celestial Journey", seconds: 260 },
    { url: staticFile("playlist/music-3.mp3"), title: "Crimson Chords", seconds: 210 },
    { url: staticFile("playlist/music-3.mp3"), title: "Golden Reflections", seconds: 200 },
  ],

  backgrounds: {
    url: staticFile("playlist/background.jpeg"),
    seconds: 30,
  },
  maxLoop: 1,
};

export default examplePlaylist;
