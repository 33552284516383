import {
	Img,
	interpolate,
	staticFile,
	useCurrentFrame,
	useVideoConfig,
} from "remotion";
import type { PrayerVideoSchema } from ".";
import type { z } from "zod";

interface BackgroundProps {
	images: z.infer<typeof PrayerVideoSchema>["images"];
}

export const Background: React.FC<BackgroundProps> = ({ images }) => {
	const frame = useCurrentFrame();
	const { durationInFrames } = useVideoConfig();

	const image = images.find((img) => img.label === "BACKGROUND");

	const image_path = image?.image_path;

	const imageDuration = durationInFrames;
	const startRotation = -2;

	const scale = interpolate(frame, [0, imageDuration], [1.4, 1.2], {
		extrapolateRight: "clamp",
	});
	const rotation = interpolate(frame, [0, imageDuration], [startRotation, 0], {
		extrapolateRight: "clamp",
	});

	const backgroundPath = staticFile("background.png");

	return (
		<Img
			src={image_path ?? backgroundPath}
			style={{ transform: `scale(${scale}) rotate(${rotation}deg)` }}
		/>
	);
};
