import { interpolate } from "remotion";

export const createPulseAnimation = (
	frame: number,
	duration = 60,
	minValue = 1,
	maxValue = 1.2,
) => {
	return interpolate(
		frame % duration,
		[0, duration / 2, duration],
		[minValue, maxValue, minValue],
		{
			extrapolateRight: "wrap",
		},
	);
};

export const createRotationAnimation = (frame: number, duration = 120) => {
	return interpolate(frame, [0, duration], [0, 360], {
		extrapolateRight: "wrap",
	});
};

export const createWaveAnimation = (
	frame: number,
	offset: number,
	duration = 60,
	minHeight = 20,
	maxHeight = 100,
) => {
	// Use interpolate to calculate the wave height based on the current frame
	return interpolate(
		(frame + offset) % duration,
		[0, 15, 30, 45, duration],
		[minHeight, maxHeight, minHeight * 1.5, maxHeight * 0.8, minHeight],
		{
			extrapolateRight: "wrap",
		},
	);
};
