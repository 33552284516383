export const themes = [
	{
		primary: "#D1B2FF",
		secondary: "#A6D4FF",
		accent: "#FFB3D9",
		background: "#F1F1F1",
		light: "#E6E6F7",
		dark: "#7E7E7E",
	},
	{
		primary: "#B5E8A3",
		secondary: "#A9D3B7",
		accent: "#FFB7A4",
		background: "#F4F9F4",
		light: "#F6F8F3",
		dark: "#808080",
	},
	{
		primary: "#A3C9FF",
		secondary: "#D9E7FF",
		accent: "#F6A7C1",
		background: "#F9F9FF",
		light: "#E7D8F5",
		dark: "#636363",
	},
	{
		primary: "#FFB6C1",
		secondary: "#D8A9FF",
		accent: "#A8F0F2",
		background: "#F7F8F9",
		light: "#F9E7E7",
		dark: "#555555",
	},
	{
		primary: "#FFB5E8",
		secondary: "#B5DEFF",
		accent: "#E7FFAC",
		background: "#FFF0F5",
		light: "#F8F8FF",
		dark: "#6E6E6E",
	},
	{
		primary: "#FFD700",
		secondary: "#FF6347",
		accent: "#32CD32",
		background: "#20B2AA",
		light: "#F0E68C",
		dark: "#800080",
	},
	{
		primary: "#9370DB",
		secondary: "#FF4500",
		accent: "#98FB98",
		background: "#F0F8FF",
		light: "#FFE4E1",
		dark: "#2F4F4F",
	},
	{
		primary: "#FF1493",
		secondary: "#ADFF2F",
		accent: "#20B2AA",
		background: "#F5FFFA",
		light: "#FFF5EE",
		dark: "#2E8B57",
	},
	{
		primary: "#00CED1",
		secondary: "#FF8C00",
		accent: "#8A2BE2",
		background: "#FFF5EE",
		light: "#FFFAF0",
		dark: "#000080",
	},
];

export const generateGradient = (frame: number): string => {
	const hue = (frame * 0.2) % 360;
	return `linear-gradient(135deg, 
      hsl(${hue}, 100%, 95%) 0%,
      hsl(${(hue + 30) % 360}, 100%, 97%) 100%
    )`;
};
