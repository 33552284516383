import { Audio, interpolate, Sequence, staticFile } from "remotion";
import { AbsoluteFill } from "remotion";
import { z } from "zod";
import { Captions } from "./Captions";
import { Avatar } from "./Avatar";
import { Background } from "./Background";
import { Sparkles } from "./Sparkles";
import Fireflies from "./Firefly";

export const PrayerVideoSchema = z.object({
	title: z.string(),
	duration_in_seconds: z.number(),
	voice_over_transcription: z.object({
		chunks: z.array(
			z.object({
				text: z.string(),
				timestamp: z.array(z.number()),
			}),
		),
		text: z.string(),
	}),
	images: z.array(
		z.object({
			url: z.string(),
			label: z.enum(["BACKGROUND", "PEOPLE"]),
		}),
	),
	voice_over_url: z.string(),
	background_audio_url: z.string(),
});

export const PrayerVideo: React.FC<z.infer<typeof PrayerVideoSchema>> = ({
	voice_over_url,
	images,
	voice_over_transcription,
	background_audio_url,
}) => {
	return (
		<AbsoluteFill>
			<Audio src={voice_over_url} playbackRate={0.85} />
			<Audio
				src={background_audio_url}
				volume={0.15}
			/>
			<Sequence name="Sparkles" className="z-10">
				<Sparkles />
			</Sequence>
			<Sequence name="Fireflies" className="z-10">
				<Fireflies />
			</Sequence>
			<Sequence name="Background">
				<Background images={images} />
			</Sequence>
			<Sequence name="Avatar">
				<Avatar images={images} />
			</Sequence>
			<Sequence name="Captions" className="z-">
				<Captions wordChunks={voice_over_transcription.chunks} />
			</Sequence>
		</AbsoluteFill>
	);
};
