import {
	AbsoluteFill,
	Sequence,
	useCurrentFrame,
	useVideoConfig,
} from "remotion";
import { Word } from "./Word";
import type { ShortVideoSchema } from ".";
import type { z } from "zod";

type WordChunk = z.infer<
	typeof ShortVideoSchema
>["voice_over_transcription"]["chunks"][number];

export const Captions: React.FC<{ wordChunks: WordChunk[] }> = ({
	wordChunks,
}) => {
	const frame = useCurrentFrame();
	const { fps } = useVideoConfig();
	const captionChunks: {
		start: number;
		end: number;
		durationInFrames: number;
		words: { text: string; start: number; end: number }[];
	}[] = [];
	let count = 0;
	let currentChunk: { text: string; start: number; end: number }[] = [];
	for (const word of wordChunks) {
		currentChunk.push({
			text: word.text,
			start: word.timestamp[0] * fps,
			end: word.timestamp[1] * fps,
		});
		count++;
		const wordContainsPunctuation = word.text.match(/[\.\?\!\,]/);
		if (currentChunk[currentChunk.length - 1].end - currentChunk[0].start < 0) {
			console.log(currentChunk);
		}
		if (count === 6 || (count >= 4 && wordContainsPunctuation)) {
			count = 0;
			captionChunks.push({
				start: currentChunk[0].start,
				end: currentChunk[currentChunk.length - 1].end,
				durationInFrames:
					currentChunk[currentChunk.length - 1].end - currentChunk[0].start,
				words: currentChunk,
			});
			currentChunk = [];
		}
	}

	return captionChunks.map((chunk) => {
		return (
			<Sequence
				key={String(chunk.start)}
				durationInFrames={chunk.durationInFrames}
				from={chunk.start}
			>
				<AbsoluteFill>
					<div
						style={{
							textAlign: "center",
							position: "absolute",
							bottom: "20%",
							left: "10%",
							right: "10%",
						}}
					>
						{chunk.words.map((word) => {
							return (
								<Word key={String(word.start)} word={word} frame={frame} />
							);
						})}
					</div>
				</AbsoluteFill>
			</Sequence>
		);
	});
};
