import {
	AbsoluteFill,
	Audio,
	Img,
	Sequence,
	interpolate,
} from "remotion";
import { z } from "zod";

export const PlaylistVideoSchema = z.object({
	title: z.string(),
	subtitle: z.string(),
	musics: z.array(
		z.object({
			url: z.string(),
			title: z.string(),
			seconds: z.number(),
		}),
	),
	backgrounds: z.object({
		url: z.string(),
		seconds: z.number(),
	}),
	maxLoop: z.number(),
});

type PlaylistVideoProps = z.infer<typeof PlaylistVideoSchema>;

export const PlaylistVideo: React.FC<PlaylistVideoProps> = ({
	musics,
	backgrounds,
	maxLoop,
}) => {
	const fps = 30;
	const fadeDurationInFrames = fps * 5; // 2 seconds fade in/out

	let currentFrom = 0;

	return (
		<AbsoluteFill className="bg-white">
			{/* Looping the background video or displaying an image */}
			<Sequence>
				<Img
					src={backgrounds.url}
					style={{
						scale:'1.0125',
						width: "100%",
						height: "auto",
						objectFit: "cover",
					}}
				/>
			</Sequence>

			{Array.from({ length: maxLoop }).flatMap((_, loopIndex) =>
				musics.map((music, index) => {
					const durationInFrames = music.seconds * fps;
					const from = currentFrom; // Current starting frame
					currentFrom += durationInFrames; // Update the starting frame for the next sequence

					return (
						<Sequence
							key={`loop-${loopIndex}-music-${index}`}
							name={`Music ${index + 1} (Loop ${loopIndex + 1})`}
							durationInFrames={durationInFrames}
							from={from}
						>
							<Audio
								src={music.url}
								volume={(frame) =>
									interpolate(
										frame,
										[
											0,
											fadeDurationInFrames,
											durationInFrames - fadeDurationInFrames,
											durationInFrames,
										],
										[0, 1, 1, 0],
										{
											extrapolateLeft: "clamp",
											extrapolateRight: "clamp",
										},
									)
								}
							/>
						</Sequence>
					);
				}),
			)}
		</AbsoluteFill>
	);
};
