import { useMemo } from "react";
import { useCurrentFrame } from "remotion";
import { createWaveAnimation } from "../../utils/animations";
import { themes } from "../../utils/colors";
import type { ColorsProps } from "../example";

const defaultProps: Required<WaveProps> = {
	barCount: 32,
	barWidth: 12,
	barGap: 12,
	height: 240,
	minHeight: 20,
	maxHeight: 180,
	colors: themes[0],
	duration: 60,
	opacity: 0.8,
};

export const AudioWaves: React.FC<WaveProps> = (userProps) => {
	const props = { ...defaultProps, ...userProps };

	return (
		<div
			style={{
				display: "flex",
				gap: props.barGap,
				height: props.height,
				alignItems: "center",
			}}
		>
			{Array.from({ length: props.barCount }).map((_, i) => (
				<WaveBar
					key={i}
					index={i}
					width={props.barWidth}
					colors={props.colors}
					minHeight={props.minHeight}
					maxHeight={props.maxHeight}
					duration={props.duration}
					opacity={props.opacity}
				/>
			))}
		</div>
	);
};

export interface WaveProps {
	barCount?: number;
	barWidth?: number;
	barGap?: number;
	height?: number;
	minHeight?: number;
	maxHeight?: number;
	colors?: ColorsProps;
	duration?: number;
	opacity?: number;
}

interface WaveBarProps {
	index: number;
	width: number;
	colors: ColorsProps;
	minHeight: number;
	maxHeight: number;
	duration: number;
	opacity: number;
}

export const WaveBar: React.FC<WaveBarProps> = ({
	index,
	width,
	colors,
	minHeight,
	maxHeight,
	duration,
	opacity,
}) => {
	const themes = [colors.primary, colors.secondary, colors.accent];
	const frame = useCurrentFrame();

	const height = useMemo(
		() => createWaveAnimation(frame, index * 5, duration, minHeight, maxHeight),
		[frame, index, duration, minHeight, maxHeight],
	);

	return (
		<div
			style={{
				width,
				height,
				backgroundColor: themes[index % themes.length],
				borderRadius: width / 2,
				opacity,
			}}
		/>
	);
};
