import { AbsoluteFill, Img, staticFile } from "remotion";
import type { PrayerVideoSchema } from ".";
import type { z } from "zod";

type AvatarProps = {
	images: z.infer<typeof PrayerVideoSchema>["images"];
};

export const Avatar: React.FC<AvatarProps> = ({ images }) => {
	const avatarPath = staticFile("avatar.png");
	const avatar = images.find((img) => img.label === "PEOPLE");

	return (
		<AbsoluteFill
			style={{
				justifyContent: "flex-end",
				alignItems: "flex-end",
				paddingRight: "5%",
			}}
		>
			<Img
				src={avatar?.url ?? avatarPath}
				alt="Avatar"
				style={{ width: "640px", height: "auto" }}
			/>
		</AbsoluteFill>
	);
};
