import { Audio } from "remotion";
import { AbsoluteFill } from "remotion";
import { z } from "zod";
import { Images } from "./Images";
import { Captions } from "./Captions";

export const ShortVideoSchema = z.object({
	language: z.string(),
	title: z.string(),
	duration_in_seconds: z.number(),
	voice_over_transcription: z.object({
		chunks: z.array(
			z.object({
				text: z.string(),
				timestamp: z.array(z.number()),
			}),
		),
		text: z.string(),
	}),
	images: z.array(
		z.object({
			image_path: z.string(),
			start: z.number(),
			end: z.number(),
		}),
	),
	voice_over_url: z.string(),
});

export const ShortVideo: React.FC<z.infer<typeof ShortVideoSchema>> = (
	props,
) => {
	const { voice_over_url, images, voice_over_transcription } = props;
	return (
		<AbsoluteFill style={{ backgroundColor: "white" }}>
			{/* <Audio src={voice_over_url} /> */}
			<Images images={images} />
			<Captions wordChunks={voice_over_transcription?.chunks ?? []} />
		</AbsoluteFill>
	);
};
