import { loadFont } from "@remotion/google-fonts/Montserrat";

// Load custom font
const { fontFamily } = loadFont();

interface WordProps {
	word: {
		text: string;
	};
	frame: number;
	fontSize?: number;
}

export const Word: React.FC<WordProps> = ({ word, frame, fontSize = 52 }) => {
	const wordStyle = {
		fontSize,
		fontFamily,
		fontWeight: "bold",
		zIndex: 1,
		color: "white",
		opacity: 1,
		textShadow:
			"3px 3px 0 #000, -3px 3px 0 #000, -3px -3px 0 #000, 3px -3px 0 #000",
		position: "relative",
	};

	const wrapperStyle = {
		display: "inline-block",
		paddingLeft: 10,
		paddingRight: 10,
		position: "relative",
	};

	const style = {
		...wordStyle,
	};

	return (
		<div style={wrapperStyle}>
			<span style={style}>{word.text}</span>
		</div>
	);
};
