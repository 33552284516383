import { AbsoluteFill, Audio, Sequence, interpolate } from "remotion";
import { VinylRecord } from "./components/vinyl-record";
import { useLofiVideo } from "../hook/use-lofi-video";
import { AlbumInfo } from "./components/album-info";
import { AudioWaves } from "./components/audio-wave";
import type { LofiVideoProps } from "./example";
import { themes } from "../utils/colors";

export const LofiVideo: React.FC<LofiVideoProps> = (props) => {
	const { musics, music_background_url, theme } = props;
	const { isValid, currentTrack } = useLofiVideo(props);
	const colors = themes[theme];

	if (!isValid) {
		console.error("Invalid LofiVideo props");
		return null;
	}

	const fps = 30;
	const fadeDurationInFrames = fps * 5; // 2 seconds fade in/out

	let currentFrom = 0;

	return (
		<AbsoluteFill
			style={{
				backgroundColor: colors.background,
			}}
		>
			<AbsoluteFill className="flex items-center justify-center bg-white/10">
				<VinylRecord />

				<AudioWaves colors={colors} />

				<AlbumInfo
					currentTrack={currentTrack}
					totalTracks={props.musics.length}
					musics={props.musics}
					colors={colors}
				/>
			</AbsoluteFill>

			{musics.map((music, index) => {
				const durationInFrames = music.seconds * fps;
				const from = currentFrom; // Current starting frame
				currentFrom += durationInFrames; // Update the starting frame for the next sequence

				return (
					<Sequence
						key={`music-${index + 1}`}
						name={`Music ${index + 1}`}
						durationInFrames={durationInFrames}
						from={from}
					>
						<Audio
							src={music.url}
							volume={(frame) =>
								interpolate(
									frame,
									[
										0,
										fadeDurationInFrames,
										durationInFrames - fadeDurationInFrames,
										durationInFrames,
									],
									[0, 1, 1, 0],
									{
										extrapolateLeft: "clamp",
										extrapolateRight: "clamp",
									},
								)
							}
						/>
					</Sequence>
				);
			})}
			{music_background_url ? (
				<Sequence>
					<Audio src={music_background_url} volume={0.3} loop />
				</Sequence>
			) : null}
		</AbsoluteFill>
	);
};
