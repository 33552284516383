import type { ShortVideoSchema } from ".";
import type { z } from "zod";

const example: z.infer<typeof ShortVideoSchema> = {
	language: "English",
	title: "Rome's Darkest Hour of Need",
	duration_in_seconds: 97.824,
	voice_over_transcription: {
		chunks: [
			{
				text: " As",
				timestamp: [0, 0.08],
			},
			{
				text: " the",
				timestamp: [0.08, 0.22],
			},
			{
				text: " sun",
				timestamp: [0.22, 0.44],
			},
			{
				text: " set",
				timestamp: [0.44, 0.68],
			},
			{
				text: " over",
				timestamp: [0.68, 1.02],
			},
			{
				text: " the",
				timestamp: [1.02, 1.2],
			},
			{
				text: " Eternal",
				timestamp: [1.2, 1.46],
			},
			{
				text: " City,",
				timestamp: [1.46, 2.02],
			},
			{
				text: " casting",
				timestamp: [2.02, 2.46],
			},
			{
				text: " a",
				timestamp: [2.46, 2.62],
			},
			{
				text: " golden",
				timestamp: [2.62, 2.9],
			},
			{
				text: " glow",
				timestamp: [2.9, 3.22],
			},
			{
				text: " over",
				timestamp: [3.22, 3.68],
			},
			{
				text: " the",
				timestamp: [3.68, 3.82],
			},
			{
				text: " sprawling",
				timestamp: [3.82, 4.32],
			},
			{
				text: " metropolis,",
				timestamp: [4.32, 5.32],
			},
			{
				text: " Emperor",
				timestamp: [5.32, 5.76],
			},
			{
				text: " Aurelian",
				timestamp: [5.76, 6.36],
			},
			{
				text: " stood",
				timestamp: [6.36, 6.6],
			},
			{
				text: " atop",
				timestamp: [6.6, 7.02],
			},
			{
				text: " the",
				timestamp: [7.02, 7.2],
			},
			{
				text: " walls,",
				timestamp: [7.2, 7.68],
			},
			{
				text: " gazing",
				timestamp: [7.68, 8.3],
			},
			{
				text: " out",
				timestamp: [8.3, 8.56],
			},
			{
				text: " at",
				timestamp: [8.56, 8.88],
			},
			{
				text: " the",
				timestamp: [8.88, 8.96],
			},
			{
				text: " gathering",
				timestamp: [8.96, 9.3],
			},
			{
				text: " storm.",
				timestamp: [9.3, 10.1],
			},
			{
				text: " The",
				timestamp: [10.1, 10.7],
			},
			{
				text: " once",
				timestamp: [10.7, 10.88],
			},
			{
				text: " mighty",
				timestamp: [10.88, 11.2],
			},
			{
				text: " Roman",
				timestamp: [11.2, 11.58],
			},
			{
				text: " Empire",
				timestamp: [11.58, 11.98],
			},
			{
				text: " was",
				timestamp: [11.98, 12.28],
			},
			{
				text: " crumbling,",
				timestamp: [12.28, 12.92],
			},
			{
				text: " its",
				timestamp: [12.92, 13.38],
			},
			{
				text: " borders",
				timestamp: [13.38, 13.78],
			},
			{
				text: " beset",
				timestamp: [13.78, 14.28],
			},
			{
				text: " on",
				timestamp: [14.28, 14.46],
			},
			{
				text: " all",
				timestamp: [14.46, 14.74],
			},
			{
				text: " sides",
				timestamp: [14.74, 15.12],
			},
			{
				text: " by",
				timestamp: [15.12, 15.48],
			},
			{
				text: " barbarian",
				timestamp: [15.48, 16.12],
			},
			{
				text: " hordes",
				timestamp: [16.12, 16.74],
			},
			{
				text: " and",
				timestamp: [16.74, 16.82],
			},
			{
				text: " internal",
				timestamp: [16.82, 17.08],
			},
			{
				text: " strife.",
				timestamp: [17.08, 17.88],
			},
			{
				text: " The",
				timestamp: [17.88, 18.5],
			},
			{
				text: " people",
				timestamp: [18.5, 18.7],
			},
			{
				text: " were",
				timestamp: [18.7, 18.9],
			},
			{
				text: " hungry,",
				timestamp: [18.9, 19.42],
			},
			{
				text: " the",
				timestamp: [19.42, 19.82],
			},
			{
				text: " treasury",
				timestamp: [19.82, 20.18],
			},
			{
				text: " was",
				timestamp: [20.18, 20.46],
			},
			{
				text: " depleted,",
				timestamp: [20.46, 21.12],
			},
			{
				text: " and",
				timestamp: [21.12, 21.52],
			},
			{
				text: " the",
				timestamp: [21.52, 21.6],
			},
			{
				text: " gods",
				timestamp: [21.6, 21.92],
			},
			{
				text: " seemed",
				timestamp: [21.92, 22.32],
			},
			{
				text: " to",
				timestamp: [22.32, 22.5],
			},
			{
				text: " have",
				timestamp: [22.5, 22.62],
			},
			{
				text: " turned",
				timestamp: [22.62, 22.88],
			},
			{
				text: " their",
				timestamp: [22.88, 23.08],
			},
			{
				text: " backs",
				timestamp: [23.08, 23.38],
			},
			{
				text: " on",
				timestamp: [23.38, 23.6],
			},
			{
				text: " Rome.",
				timestamp: [23.6, 24.32],
			},
			{
				text: " Aurelian's",
				timestamp: [24.32, 25.16],
			},
			{
				text: " thoughts",
				timestamp: [25.16, 25.38],
			},
			{
				text: " were",
				timestamp: [25.38, 25.64],
			},
			{
				text: " interrupted",
				timestamp: [25.64, 26.08],
			},
			{
				text: " by",
				timestamp: [26.08, 26.4],
			},
			{
				text: " the",
				timestamp: [26.4, 26.52],
			},
			{
				text: " arrival",
				timestamp: [26.52, 26.96],
			},
			{
				text: " of",
				timestamp: [26.96, 27.2],
			},
			{
				text: " his",
				timestamp: [27.2, 27.42],
			},
			{
				text: " trusted",
				timestamp: [27.42, 27.76],
			},
			{
				text: " advisor,",
				timestamp: [27.76, 28.58],
			},
			{
				text: " Senator",
				timestamp: [28.58, 29.16],
			},
			{
				text: " Marcellus.",
				timestamp: [29.16, 29.94],
			},
			{
				text: " Sire,",
				timestamp: [29.94, 30.56],
			},
			{
				text: " the",
				timestamp: [30.56, 30.74],
			},
			{
				text: " situation",
				timestamp: [30.74, 31.06],
			},
			{
				text: " grows",
				timestamp: [31.06, 31.54],
			},
			{
				text: " more",
				timestamp: [31.54, 31.88],
			},
			{
				text: " dire",
				timestamp: [31.88, 32.16],
			},
			{
				text: " by",
				timestamp: [32.16, 32.38],
			},
			{
				text: " the",
				timestamp: [32.38, 32.5],
			},
			{
				text: " hour,",
				timestamp: [32.5, 32.98],
			},
			{
				text: " Marcellus",
				timestamp: [32.98, 33.52],
			},
			{
				text: " warned,",
				timestamp: [33.52, 34.08],
			},
			{
				text: " his",
				timestamp: [34.08, 34.4],
			},
			{
				text: " voice",
				timestamp: [34.4, 34.7],
			},
			{
				text: " low",
				timestamp: [34.7, 34.98],
			},
			{
				text: " and",
				timestamp: [34.98, 35.18],
			},
			{
				text: " urgent.",
				timestamp: [35.18, 35.8],
			},
			{
				text: " The",
				timestamp: [35.8, 36.4],
			},
			{
				text: " vandals",
				timestamp: [36.4, 36.82],
			},
			{
				text: " are",
				timestamp: [36.82, 36.96],
			},
			{
				text: " at",
				timestamp: [36.96, 37.08],
			},
			{
				text: " the",
				timestamp: [37.08, 37.16],
			},
			{
				text: " gates,",
				timestamp: [37.16, 37.68],
			},
			{
				text: " and",
				timestamp: [37.68, 37.96],
			},
			{
				text: " our",
				timestamp: [37.96, 38.08],
			},
			{
				text: " legions",
				timestamp: [38.08, 38.56],
			},
			{
				text: " are",
				timestamp: [38.56, 38.7],
			},
			{
				text: " scattered",
				timestamp: [38.7, 39.14],
			},
			{
				text: " and",
				timestamp: [39.14, 39.34],
			},
			{
				text: " leaderless.",
				timestamp: [39.34, 39.92],
			},
			{
				text: " We",
				timestamp: [39.92, 40.3],
			},
			{
				text: " must",
				timestamp: [40.3, 40.5],
			},
			{
				text: " act",
				timestamp: [40.5, 40.78],
			},
			{
				text: " swiftly",
				timestamp: [40.78, 41.32],
			},
			{
				text: " or",
				timestamp: [41.32, 41.68],
			},
			{
				text: " risk",
				timestamp: [41.68, 41.94],
			},
			{
				text: " losing",
				timestamp: [41.94, 42.28],
			},
			{
				text: " everything.",
				timestamp: [42.28, 43.3],
			},
			{
				text: " Aurelian's",
				timestamp: [43.3, 44.88],
			},
			{
				text: " jaw",
				timestamp: [44.88, 45.04],
			},
			{
				text: " clenched",
				timestamp: [45.04, 45.6],
			},
			{
				text: " in",
				timestamp: [45.6, 45.76],
			},
			{
				text: " determination.",
				timestamp: [45.76, 46.56],
			},
			{
				text: " He",
				timestamp: [46.56, 47.24],
			},
			{
				text: " knew",
				timestamp: [47.24, 47.44],
			},
			{
				text: " that",
				timestamp: [47.44, 47.62],
			},
			{
				text: " Rome's",
				timestamp: [47.62, 47.98],
			},
			{
				text: " darkest",
				timestamp: [47.98, 48.3],
			},
			{
				text: " hour",
				timestamp: [48.3, 48.6],
			},
			{
				text: " of",
				timestamp: [48.6, 48.86],
			},
			{
				text: " need",
				timestamp: [48.86, 49.06],
			},
			{
				text: " had",
				timestamp: [49.06, 49.28],
			},
			{
				text: " arrived,",
				timestamp: [49.28, 49.86],
			},
			{
				text: " and",
				timestamp: [49.86, 50.3],
			},
			{
				text: " that",
				timestamp: [50.3, 50.4],
			},
			{
				text: " he",
				timestamp: [50.4, 50.54],
			},
			{
				text: " was",
				timestamp: [50.54, 50.72],
			},
			{
				text: " the",
				timestamp: [50.72, 50.86],
			},
			{
				text: " only",
				timestamp: [50.86, 51.2],
			},
			{
				text: " one",
				timestamp: [51.2, 51.46],
			},
			{
				text: " who",
				timestamp: [51.46, 51.64],
			},
			{
				text: " could",
				timestamp: [51.64, 51.76],
			},
			{
				text: " save",
				timestamp: [51.76, 52.04],
			},
			{
				text: " it.",
				timestamp: [52.04, 52.46],
			},
			{
				text: " Summoning",
				timestamp: [52.46, 53.36],
			},
			{
				text: " every",
				timestamp: [53.36, 53.58],
			},
			{
				text: " last",
				timestamp: [53.58, 53.9],
			},
			{
				text: " ounce",
				timestamp: [53.9, 54.24],
			},
			{
				text: " of",
				timestamp: [54.24, 54.4],
			},
			{
				text: " strength",
				timestamp: [54.4, 54.76],
			},
			{
				text: " and",
				timestamp: [54.76, 54.94],
			},
			{
				text: " cunning,",
				timestamp: [54.94, 55.46],
			},
			{
				text: " he",
				timestamp: [55.46, 55.88],
			},
			{
				text: " rallied",
				timestamp: [55.88, 56.2],
			},
			{
				text: " his",
				timestamp: [56.2, 56.36],
			},
			{
				text: " remaining",
				timestamp: [56.36, 56.62],
			},
			{
				text: " troops",
				timestamp: [56.62, 56.98],
			},
			{
				text: " and",
				timestamp: [56.98, 57.42],
			},
			{
				text: " launched",
				timestamp: [57.42, 57.74],
			},
			{
				text: " a",
				timestamp: [57.74, 57.92],
			},
			{
				text: " desperate",
				timestamp: [57.92, 58.26],
			},
			{
				text: " counterattack",
				timestamp: [58.26, 58.96],
			},
			{
				text: " against",
				timestamp: [58.96, 59.38],
			},
			{
				text: " the",
				timestamp: [59.38, 59.6],
			},
			{
				text: " invaders.",
				timestamp: [59.6, 60.3],
			},
			{
				text: " The",
				timestamp: [60.3, 61.04],
			},
			{
				text: " battle",
				timestamp: [61.04, 61.34],
			},
			{
				text: " raged",
				timestamp: [61.34, 61.72],
			},
			{
				text: " on",
				timestamp: [61.72, 61.94],
			},
			{
				text: " for",
				timestamp: [61.94, 62.1],
			},
			{
				text: " hours,",
				timestamp: [62.1, 62.72],
			},
			{
				text: " the",
				timestamp: [62.72, 63.06],
			},
			{
				text: " outcome",
				timestamp: [63.06, 63.4],
			},
			{
				text: " hanging",
				timestamp: [63.4, 63.74],
			},
			{
				text: " precariously",
				timestamp: [63.74, 64.58],
			},
			{
				text: " in",
				timestamp: [64.58, 64.9],
			},
			{
				text: " the",
				timestamp: [64.9, 64.98],
			},
			{
				text: " balance.",
				timestamp: [64.98, 65.6],
			},
			{
				text: " But",
				timestamp: [65.6, 66.38],
			},
			{
				text: " Aurelian's",
				timestamp: [66.38, 66.96],
			},
			{
				text: " bravery",
				timestamp: [66.96, 67.28],
			},
			{
				text: " and",
				timestamp: [67.28, 67.76],
			},
			{
				text: " tactical",
				timestamp: [67.76, 68.2],
			},
			{
				text: " genius",
				timestamp: [68.2, 68.64],
			},
			{
				text: " eventually",
				timestamp: [68.64, 69.14],
			},
			{
				text: " paid",
				timestamp: [69.14, 69.52],
			},
			{
				text: " off,",
				timestamp: [69.52, 69.96],
			},
			{
				text: " as",
				timestamp: [69.96, 70.46],
			},
			{
				text: " the",
				timestamp: [70.46, 70.6],
			},
			{
				text: " Vandals",
				timestamp: [70.6, 70.98],
			},
			{
				text: " were",
				timestamp: [70.98, 71.18],
			},
			{
				text: " repelled",
				timestamp: [71.18, 71.72],
			},
			{
				text: " and",
				timestamp: [71.72, 71.92],
			},
			{
				text: " the",
				timestamp: [71.92, 72.04],
			},
			{
				text: " city",
				timestamp: [72.04, 72.28],
			},
			{
				text: " was",
				timestamp: [72.28, 72.46],
			},
			{
				text: " saved.",
				timestamp: [72.46, 73.2],
			},
			{
				text: " As",
				timestamp: [73.2, 74.12],
			},
			{
				text: " the",
				timestamp: [74.12, 74.26],
			},
			{
				text: " dust",
				timestamp: [74.26, 74.46],
			},
			{
				text: " settled,",
				timestamp: [74.46, 75.02],
			},
			{
				text: " the",
				timestamp: [75.02, 75.24],
			},
			{
				text: " emperor",
				timestamp: [75.24, 75.52],
			},
			{
				text: " stood",
				timestamp: [75.52, 75.82],
			},
			{
				text: " victorious,",
				timestamp: [75.82, 76.68],
			},
			{
				text: " his",
				timestamp: [76.68, 77.32],
			},
			{
				text: " armor",
				timestamp: [77.32, 77.64],
			},
			{
				text: " battered",
				timestamp: [77.64, 78.24],
			},
			{
				text: " but",
				timestamp: [78.24, 78.36],
			},
			{
				text: " his",
				timestamp: [78.36, 78.48],
			},
			{
				text: " spirit",
				timestamp: [78.48, 78.82],
			},
			{
				text: " unbroken.",
				timestamp: [78.82, 79.64],
			},
			{
				text: " For",
				timestamp: [79.64, 80.4],
			},
			{
				text: " a",
				timestamp: [80.4, 80.52],
			},
			{
				text: " moment,",
				timestamp: [80.52, 80.96],
			},
			{
				text: " the",
				timestamp: [80.96, 81.2],
			},
			{
				text: " people",
				timestamp: [81.2, 81.42],
			},
			{
				text: " of",
				timestamp: [81.42, 81.64],
			},
			{
				text: " Rome",
				timestamp: [81.64, 81.88],
			},
			{
				text: " forgot",
				timestamp: [81.88, 82.16],
			},
			{
				text: " their",
				timestamp: [82.16, 82.46],
			},
			{
				text: " troubles",
				timestamp: [82.46, 82.8],
			},
			{
				text: " and",
				timestamp: [82.8, 83.22],
			},
			{
				text: " rejoiced",
				timestamp: [83.22, 83.96],
			},
			{
				text: " at",
				timestamp: [83.96, 84.14],
			},
			{
				text: " their",
				timestamp: [84.14, 84.28],
			},
			{
				text: " narrow",
				timestamp: [84.28, 84.56],
			},
			{
				text: " escape.",
				timestamp: [84.56, 85.18],
			},
			{
				text: " But",
				timestamp: [85.18, 85.6],
			},
			{
				text: " Aurelian",
				timestamp: [85.6, 86.14],
			},
			{
				text: " knew",
				timestamp: [86.14, 86.48],
			},
			{
				text: " that",
				timestamp: [86.48, 86.76],
			},
			{
				text: " the",
				timestamp: [86.76, 86.88],
			},
			{
				text: " real",
				timestamp: [86.88, 87.14],
			},
			{
				text: " work",
				timestamp: [87.14, 87.42],
			},
			{
				text: " was",
				timestamp: [87.42, 87.62],
			},
			{
				text: " only",
				timestamp: [87.62, 87.86],
			},
			{
				text: " just",
				timestamp: [87.86, 88.1],
			},
			{
				text: " beginning.",
				timestamp: [88.1, 88.78],
			},
			{
				text: " He",
				timestamp: [88.78, 89.34],
			},
			{
				text: " vowed",
				timestamp: [89.34, 89.68],
			},
			{
				text: " to",
				timestamp: [89.68, 89.78],
			},
			{
				text: " rebuild",
				timestamp: [89.78, 90.12],
			},
			{
				text: " and",
				timestamp: [90.12, 90.56],
			},
			{
				text: " restore",
				timestamp: [90.56, 90.9],
			},
			{
				text: " the",
				timestamp: [90.9, 91.14],
			},
			{
				text: " empire",
				timestamp: [91.14, 91.36],
			},
			{
				text: " to",
				timestamp: [91.36, 91.72],
			},
			{
				text: " its",
				timestamp: [91.72, 91.86],
			},
			{
				text: " former",
				timestamp: [91.86, 92.12],
			},
			{
				text: " glory,",
				timestamp: [92.12, 92.66],
			},
			{
				text: " and",
				timestamp: [92.66, 93.24],
			},
			{
				text: " to",
				timestamp: [93.24, 93.32],
			},
			{
				text: " ensure",
				timestamp: [93.32, 93.58],
			},
			{
				text: " that",
				timestamp: [93.58, 93.82],
			},
			{
				text: " Rome",
				timestamp: [93.82, 94.12],
			},
			{
				text: " would",
				timestamp: [94.12, 94.3],
			},
			{
				text: " never",
				timestamp: [94.3, 94.54],
			},
			{
				text: " again",
				timestamp: [94.54, 94.82],
			},
			{
				text: " face",
				timestamp: [94.82, 95.16],
			},
			{
				text: " such",
				timestamp: [95.16, 95.46],
			},
			{
				text: " a",
				timestamp: [95.46, 95.6],
			},
			{
				text: " dark",
				timestamp: [95.6, 95.88],
			},
			{
				text: " hour",
				timestamp: [95.88, 96.16],
			},
			{
				text: " of",
				timestamp: [96.16, 96.52],
			},
			{
				text: " need.",
				timestamp: [96.52, 97.06],
			},
		],
		text: " As the sun set over the Eternal City, casting a golden glow over the sprawling metropolis, Emperor Aurelian stood atop the walls, gazing out at the gathering storm. The once mighty Roman Empire was crumbling, its borders beset on all sides by barbarian hordes and internal strife. The people were hungry, the treasury was depleted, and the gods seemed to have turned their backs on Rome. Aurelian's thoughts were interrupted by the arrival of his trusted advisor, Senator Marcellus. Sire, the situation grows more dire by the hour, Marcellus warned, his voice low and urgent. The vandals are at the gates, and our legions are scattered and leaderless. We must act swiftly or risk losing everything. Aurelian's jaw clenched in determination. He knew that Rome's darkest hour of need had arrived, and that he was the only one who could save it. Summoning every last ounce of strength and cunning, he rallied his remaining troops and launched a desperate counterattack against the invaders. The battle raged on for hours, the outcome hanging precariously in the balance. But Aurelian's bravery and tactical genius eventually paid off, as the Vandals were repelled and the city was saved. As the dust settled, the emperor stood victorious, his armor battered but his spirit unbroken. For a moment, the people of Rome forgot their troubles and rejoiced at their narrow escape. But Aurelian knew that the real work was only just beginning. He vowed to rebuild and restore the empire to its former glory, and to ensure that Rome would never again face such a dark hour of need.",
	},
	images: [
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/0.webp",
			start: 0,
			end: 17.88,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/1.webp",
			start: 17.88,
			end: 29.94,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/2.webp",
			start: 29.94,
			end: 39.92,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/3.webp",
			start: 39.92,
			end: 46.56,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/4.webp",
			start: 46.56,
			end: 60.3,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/5.webp",
			start: 60.3,
			end: 73.2,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/6.webp",
			start: 73.2,
			end: 85.18,
		},
		{
			image_path:
				"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/7.webp",
			start: 85.18,
			end: 97.06,
		},
	],
	voice_over_url:
		"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/example.mp3",
};

export default example;
