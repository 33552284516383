import type { PrayerVideoSchema } from ".";
import type { z } from "zod";

export const example: z.infer<typeof PrayerVideoSchema> = {
	title: "Doa Untuk Pembukaan Pintu Berkat",
	language: "id",
	background_audio_url: "",
	voice_over_url:
		"https://studiowonderluscdn.s3.eu-central-1.amazonaws.com/faceless-laravel/example.mp3",
	duration_in_seconds: 36,
	voice_over_transcription: {
		text: " Doa harapan untuk pertolongan Tuhan dalam hal keuangan. Ya Tuhan, aku datang dengan hati yang penuh kecemasan dan keraguan, mencari damai di hadapan-Mu. Hidupku tengah dilanda kesulitan yang besar, dan setiap langkah terasa begitu berat. Keuangan yang semakin menipis, utang yang semakin membelit, membuatku merasa tak berdaya. Namun aku percaya, hanya di dalam tanganmu ada jalan keluar.",
		chunks: [
			{
				text: " Doa harapan untuk pertolongan Tuhan dalam hal keuangan.",
				timestamp: [0, 4.66],
			},
			{
				text: " Ya Tuhan, aku datang dengan hati yang penuh kecemasan dan keraguan,",
				timestamp: [5.86, 11.5],
			},
			{
				text: " mencari damai di hadapan-Mu.",
				timestamp: [12.4, 14.3],
			},
			{
				text: " Hidupku tengah dilanda kesulitan yang besar,",
				timestamp: [15.2, 17.7],
			},
			{
				text: " dan setiap langkah terasa begitu berat.",
				timestamp: [18.4, 20.6],
			},
			{
				text: " Keuangan yang semakin menipis, utang yang semakin membelit,",
				timestamp: [21.5, 25.6],
			},
			{
				text: " membuatku merasa tak berdaya.",
				timestamp: [26.3, 28.1],
			},
			{
				text: " Namun aku percaya, hanya di dalam tanganmu ada jalan keluar.",
				timestamp: [28.1, 33.8],
			},
		],
	},
	images: [],
};

export default example;
