import { useCurrentFrame } from "remotion";
import { createRotationAnimation } from "../../utils/animations";

export const VinylRecord: React.FC = () => {
	const frame = useCurrentFrame();
	const rotation = createRotationAnimation(frame);

	return (
		<div
			className="relative w-[400px] h-[400px] rounded-full shadow-[0_6px_6px_rgba(153,144,126,0.6)]"
			style={{
				background: `linear-gradient(30deg, transparent 35%, rgba(42, 41, 40, .85) 35%) no-repeat 100% 0,
                            linear-gradient(60deg, rgba(42, 41, 40, .85) 50%, transparent 50%) no-repeat 0 100%,
                            repeating-radial-gradient(#2a2928 0 15px, #999 20px, #2a2928 25px)`, // Increased spacing
				transform: `rotate(${rotation}deg)`,
			}}
		>
			{/* Vinyl center */}
			<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[100px] h-[100px] rounded-full bg-[#b5ac9a] border-4 border-[#d9a388] shadow-[0_0_0_8px_#da5b33,inset_0_0_0_40px_#da5b33]" />
		</div>
	);
};
