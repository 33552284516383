import type { ColorsProps } from "../example";

interface AlbumInfoProps {
	currentTrack: number;
	totalTracks: number;
	musics: { title: string; url: string; seconds: number }[];
	colors: ColorsProps;
}

export const AlbumInfo: React.FC<AlbumInfoProps> = ({
	currentTrack,
	totalTracks,
	musics,
	colors,
}) => {
	const validCurrentTrack = Math.min(currentTrack, totalTracks - 1);

	return (
		<div className="flex flex-col items-center gap-2 mt-4">
			<div
				className="text-4xl opacity-80 font-sans"
				style={{
					color: colors.dark,
				}}
			>
				Track {validCurrentTrack + 1} of {totalTracks}
			</div>

			<div
				className="text-5xl font-semibold opacity-90"
				style={{
					color: colors.primary,
				}}
			>
				{musics[validCurrentTrack]?.title || "No title available"}
			</div>

			<div className="flex gap-1 items-center">
				{Array.from({ length: totalTracks }).map((_, i) => (
					<div
						key={i}
						className={`w-1.5 h-1.5 rounded-full ${
							i === validCurrentTrack
								? "bg-primary opacity-100"
								: "bg-secondary opacity-40"
						}`}
						style={{
							backgroundColor:
								i === validCurrentTrack ? colors.primary : colors.secondary,
						}}
					/>
				))}
			</div>
		</div>
	);
};
