import React from "react";
import { AbsoluteFill, Img, Sequence, staticFile } from "remotion";
import type { z } from "zod";
import type { PlaylistVideoSchema } from ".";
import { loadFont } from "@remotion/google-fonts/Poppins";
import { loadFont as loadfontLato } from "@remotion/google-fonts/Lato";
import { loadFont as loadFontAntonio } from "@remotion/google-fonts/Antonio";

const { fontFamily } = loadFont();
const { fontFamily: fontFamilyAntonio } = loadFontAntonio();
const { fontFamily: fontFamilyLato } = loadfontLato();

export const PlaylistVideoThumbnail: React.FC<
  z.infer<typeof PlaylistVideoSchema>
> = ({ musics, backgrounds, title, subtitle }) => {
  return (
    <AbsoluteFill className="bg-white">
      <Sequence>
        <Img
          src={backgrounds.url}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            zIndex: 0,
          }}
        />
      </Sequence>
      <Sequence>
        <div
          className="relative font-medium text-white z-10 flex flex-col"
          style={{
            padding: "15% 5%",
            fontSize: 36,
            WebkitTextStroke: "18px black",
            paintOrder: "stroke fill",
            fontFamily,
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tbody>
              {musics.map((music, index) => (
                <tr key={index} >
                  <td style={{ padding: "6px"}}>
                    &#9679;
                  </td>
                  <td style={{ padding: "6px" }}>{music.title}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Sequence>

      <Sequence>
        <div className="z-10 absolute -translate-x-1/2 left-1/2 text-center mt-10 w-full">
          <div
          className="text-white font-bold"
            style={{
              fontSize: 72,
              fontFamily: fontFamilyLato,
              WebkitTextStroke: "18px black",
              paintOrder: "stroke fill",
            }}
          >
            {title}
          </div>
          <div
          className="font-bold -mt-6 text-yellow-300"
            style={{
              fontSize: 96,
              fontFamily: fontFamilyAntonio,
              WebkitTextStroke: "18px black",
              paintOrder: "stroke fill",
            }}
          >
            {subtitle}
          </div>
        </div>
      </Sequence>
      <Sequence>
        <Img
          src={staticFile('playlist/shadowl-left.png')}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            zIndex: 0,
          }}
        />
      </Sequence>
      <Sequence>
        <Img
        className="absolute bottom-0 right-0 m-12"
          src={staticFile('playlist/watch.png')}
          style={{
            width: "240px",
            height: "auto",
            zIndex: 0,
          }}
        />
      </Sequence>
    </AbsoluteFill>
  );
};
