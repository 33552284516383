import { useCurrentFrame, interpolate, AbsoluteFill } from "remotion";
import { random } from "remotion";

interface FireflyProps {
	id: string;
	startX: number;
	startY: number;
	size: number;
	speed: number;
	initialAngle: number;
}

const Firefly: React.FC<FireflyProps> = ({
	startX,
	startY,
	size,
	speed,
	initialAngle,
}) => {
	const frame = useCurrentFrame();

	// Memperbarui angle pada setiap frame untuk gerakan acak
	const angle =
		initialAngle + interpolate(frame, [0, 200], [-0.5, 0.5]) * Math.PI;

	// Menghitung posisi x dan y
	const x = startX + speed * Math.cos(angle) * frame * 0.02;
	const y = startY + speed * Math.sin(angle) * frame * 0.02;

	// Cek apakah firefly keluar dari layar
	const offScreen = x < 0 || x > 100 || y < 0 || y > 100;

	const scale = 1 + 0.8 * Math.sin((frame * speed) / 10);

	const opacity = interpolate(scale, [0.2, 1], [0, 1]);

	// Jika offScreen bernilai true, tidak menampilkan firefly
	if (offScreen || scale < 0.2 || opacity < 0.1) return null;

	return (
		<div
			style={{
				position: "absolute",
				left: `${x}%`,
				top: `${y}%`,
				width: size,
				transform: `scale(${scale})`,
				opacity,
				height: size,
				backgroundColor: "#fddba3",
				borderRadius: "50%",
				boxShadow: "0px 0px 10px 10px rgba(254, 236, 55, 1)",
			}}
		/>
	);
};

// Komponen utama untuk menampilkan seluruh firefly
const Fireflies: React.FC = () => {
	// Generate konfigurasi firefly
	const fireflies = Array.from({ length: 4 }, (_, i) => ({
		id: `firefly-${i}`,
		startX: random(`startX-${i}`) * 100,
		startY: random(`startY-${i}`) * 100,
		size: random(`size-${i}`) * 2 + 1,
		speed: random(`speed-${i}`) * 0.5 + 0.1,
		initialAngle: random(`angle-${i}`) * Math.PI * 2,
	}));

	return (
		<AbsoluteFill>
			{fireflies.map((firefly) => (
				<Firefly
					key={firefly.id}
					id={firefly.id}
					startX={firefly.startX}
					startY={firefly.startY}
					size={firefly.size}
					speed={firefly.speed}
					initialAngle={firefly.initialAngle}
				/>
			))}
		</AbsoluteFill>
	);
};

export default Fireflies;
